import React from 'react';

const EXPLORER_URI = process.env.REACT_APP_EXPLORER_URL;

function BlockchainTransaction(props) {
    let link = `${EXPLORER_URI}/tx/${props.hash}`;
    return (
        <a href={link} target="_blank" rel="noreferrer">{props.hash}</a>
    );
}

const DatasetInfo = ({ dataset }) => {
    if (!dataset) return <p>Loading dataset information...</p>;

    let blockchainLabel;
    if (dataset.web3) {
        blockchainLabel = <BlockchainTransaction hash={dataset.web3.tx.hash} />;
    } else {
        blockchainLabel = 'Pending...';
    }

    return (
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title"><b>Dataset Id:</b> {dataset.id}</h3>
                </div>
                <div class="card-body">
                    <p><b>Flight Localization Count</b> {dataset.count}</p>
                    <p><b>Blockchain Transaction:</b> {blockchainLabel}</p>
                </div>
            </div>
        </div>
    </div>
    );
};

export default DatasetInfo;