import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DatasetPage from './components/DatasetPage';

const App = () => {
  return (
    <Router>
        <Routes>
          {/* Route to display a dataset page based on the dataset ID in the URL */}
          <Route path="/:id" element={<DatasetPage />} />
        </Routes>
    </Router>
  );
};

export default App;