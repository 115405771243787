import React from 'react';

import FlightDataEntry from './FlightDataEntry';

const FlightDataList = ({ flightData }) => {
  if (!flightData || flightData.length === 0) return <p>No flight data available.</p>;

  return (
    <div>
      {flightData.map((data) => (
        <FlightDataEntry flightData={data} key={data.id}/>
      ))}
    </div>
  );
};

export default FlightDataList;