import React from 'react';

const FlightDataEntry = ({ flightData }) => {
  return (
      <div class="row">
        <div class="col-lg-12">
          <div class="card collapsed-card">
            <div class="card-header">
              <h3 class="card-title"><b>Timestamp:</b> {flightData.timestamp}</h3>

              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <p><b>Id:</b> {flightData.id}</p>
              <p><b>Latitude:</b> {flightData.localization.latitude}</p>
              <p><b>Longitude:</b> {flightData.localization.longitude}</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default FlightDataEntry;